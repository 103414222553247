/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import React from "react"
import PropTypes from "prop-types"
import { jsx, Container } from "theme-ui"

import useSiteMetadata from "../use-site-metadata"
import HeroImage from "./hero-image"
import Social from "./social"

let additionalStyles = {}
let bgOverlayStyles = {}

const BannerContent = ({ title, tagline }) => (
  
  <>
    <iframe title="vimeo-player" src="https://player.vimeo.com/video/989523129?h=801f4b9135&autoplay=1&loop=1&background=1&mute=1" width="100%" height="1080" frameborder="0"    allowfullscreen></iframe>
  </>
)

const Banner = ({ children, bgOverlay, color }) => {
  const { title, artist, bannerImg } = useSiteMetadata()

  if (bannerImg) {
    additionalStyles["flexDirection"] = "column"
  }
  if (color) {
    additionalStyles["color"] = "color"
  }

  if (bgOverlay) {
    bgOverlayStyles = {
      "&:after": {
        background: bgOverlay,
      },
    }
  }

  const bannerContentElement = (
    <BannerContent
      title={title}
      tagline={typeof artist.tagline === "undefined" ? null : artist.tagline}
    />
  )

  return (
    <div
      className="GtmBanner"
      sx={{
        variant: "components.banner",
        ...additionalStyles,
        ...bgOverlayStyles,
      }}
    >
      {bannerImg ? (
        <HeroImage
          className="GtmBanner__hero-wrapper"
          fluid={bannerImg.fluid}
          sx={{ flexGrow: 1 }}
        >
          <Container className="GtmBanner__content-wrapper">
            {children || bannerContentElement}
          </Container>
        </HeroImage>
      ) : (
        <Container
          className="GtmBanner__content-wrapper"
          sx={{ alignSelf: "center" }}
        >
          {children || bannerContentElement}
        </Container>
      )}
    </div>
  )
}

Banner.propTypes = {
  children: PropTypes.any,
  bgOverlay: PropTypes.string,
  color: PropTypes.string,
}

export default Banner
